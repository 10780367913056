import React from "react";
import { NavLink } from "react-router-dom";
import "../assets/css/mycss.css";
import styled from "styled-components";

import civil1_2400x1600 from "../assets/img/proyecto/derecho civil/civil1.1.jpeg";

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import derechoautor from "../assets/img/proyecto/derecho civil/derechoautor.jpg";
import difamacion from "../assets/img/proyecto/derecho civil/difamacion.jpg";
import negligenciamedica from "../assets/img/proyecto/derecho civil/negligenciamedica.jpg";

export default function Civil() {
  const [t, i18n] = useTranslation("global");
  return (
    <>
      <DivContainer>
        <div className="container">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/">{t("navbar.page1")}</NavLink>
                    </li>
                    <li className="breadcrumb-item active">
                      {t("body.texto10")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>

          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators"></ol>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  className="d-block w-100"
                  src={civil1_2400x1600}
                  alt="First slide"
                />
                <div className="carousel-caption d-none d-md-block"></div>
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="sr-only">Next</span>
            </a>
          </div>
          <br /><br /><br /><br />
          <main>
          {/* Wrap the rest of the page in another container to center all the content. */}
          <div className="container marketing">
            {/* Three columns of text below the carousel */}
            <div className="row">
              <div className="col-lg-4 text-center">
              <img className="bd-placeholder-img rounded-circle" width={140} height={140} src={derechoautor} />
              <h2 className="fw-normal">{t("civil.texto1")}</h2>
                <NavLink to="/derechoautor">
                <p><a className="btn colordiv colortext" href="#">{t("textos.texto1")} »</a></p>
                    </NavLink>
                    <br />
                    </div>{/* /.col-lg-4 */}
              <div className="col-lg-4 text-center">
                <img className="bd-placeholder-img rounded-circle" width={140} height={140} src={difamacion} />
                <h2 className="fw-normal">{t("civil.texto3")}</h2>
                <NavLink to="/difamacion">
                <p><a className="btn  colordiv colortext">{t("textos.texto1")} »</a></p>
                </NavLink>
                <br />
                </div>{/* /.col-lg-4 */}
              <div className="col-lg-4 text-center">
                <img className="bd-placeholder-img rounded-circle" width={140} height={140} src={negligenciamedica} />
                <h2 className="fw-normal">{t("civil.texto4")}</h2>
                <NavLink to="/negligenciamedica">
                <p><a className="btn  colordiv colortext" href="#">{t("textos.texto1")} »</a></p>
                </NavLink>
                <br />
                </div>{/* /.col-lg-4 */}
            </div>{/* /.row */}
          </div>{/* /.container */}
        </main>

        </div>

<br />
    <nav className="text-center blog-pagination" aria-label="Pagination">
        <a className="btn btn-outline-primary rounded-pill" href="#"><FontAwesomeIcon icon={faChevronUp} /></a>
      </nav><br />        
      </DivContainer>
    </>
  );
}

const DivContainer = styled.div`
  padding: 0.5rem;
  .color-fuente {
    background-color: #222;
  }
  .colordiv {
    background-color: #001952;
  }
  .colortext {
    color: white;
  }
`;
