import React from "react";
import { NavLink } from "react-router-dom";
import "../assets/css/mycss.css";
import styled from "styled-components";

import familia1_1800x800 from "../assets/img/proyecto/derecho a la familia/familia1_1800x800.jpg";
import familia2_1800x800 from "../assets/img/proyecto/derecho a la familia/familia2_1800x800.jpg";
import familia3_1800x800 from "../assets/img/proyecto/derecho a la familia/familia3_1800x800.jpg";
import familia4_1800x800 from "../assets/img/proyecto/derecho a la familia/familia4_1800x800.jpg";

import custodia from "../assets/img/proyecto/derecho a la familia/custodia.png";
import pareja from "../assets/img/proyecto/derecho a la familia/pareja.png";
import adopcion from "../assets/img/proyecto/derecho a la familia/adopcion.png";
import divorcio from "../assets/img/proyecto/derecho a la familia/divorcio.png";

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight, faChevronUp } from "@fortawesome/free-solid-svg-icons";

export default function Familia() {
  const [t, i18n] = useTranslation("global");
  return (
    <>
      <DivContainer>
        <div className="container">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/">{t("navbar.page1")}</NavLink>
                    </li>
                    <li className="breadcrumb-item active">
                      {t("body.texto5")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>

          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators"></ol>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  className="d-block w-100"
                  src={familia1_1800x800}
                  alt="First slide"
                />
                <div className="carousel-caption d-none d-md-block"></div>
              </div>
              <div className="carousel-item">
                <img
                  className="d-block w-100"
                  src={familia2_1800x800}
                  alt="Third slide"
                />
                <div className="carousel-caption d-none d-md-block"></div>
              </div>
              <div className="carousel-item">
                <img
                  className="d-block w-100"
                  src={familia3_1800x800}
                  alt="Third slide"
                />
              </div>
              <div className="carousel-item">
                <img
                  className="d-block w-100"
                  src={familia4_1800x800}
                  alt="Third slide"
                />
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="sr-only">Next</span>
            </a>
          </div>
          <br /><br /><br /><br />
          <main>
          {/* Wrap the rest of the page in another container to center all the content. */}
          <div className="container marketing">
            {/* Three columns of text below the carousel */}
            <div className="row">
              <div className="col-lg-3 text-center">
              <img className="bd-placeholder-img rounded-circle" width={140} height={140} src={custodia} />
              <h2 className="fw-normal">{t("familia.texto1")}</h2>
                <NavLink to="/custodia">
                <p><a className="btn colordiv colortext" href="#">{t("textos.texto1")} »</a></p>
                    </NavLink>
                    <br />
                    </div>{/* /.col-lg-4 */}
              <div className="col-lg-3 text-center">
                <img className="bd-placeholder-img rounded-circle" width={140} height={140} src={pareja} />
                <h2 className="fw-normal">{t("familia.texto3")}</h2>
                <NavLink to="/parejahecho">
                <p><a className="btn  colordiv colortext">{t("textos.texto1")} »</a></p>
                </NavLink>
                <br />
                </div>{/* /.col-lg-4 */}
              <div className="col-lg-3 text-center">
                <img className="bd-placeholder-img rounded-circle" width={140} height={140} src={adopcion} />
                <h2 className="fw-normal">{t("familia.texto4")}</h2>
                <NavLink to="/adopcion">
                <p><a className="btn  colordiv colortext" href="#">{t("textos.texto1")} »</a></p>
                </NavLink>
                <br />
                </div>{/* /.col-lg-4 */}
              <div className="col-lg-3 text-center">
                <img className="bd-placeholder-img rounded-circle" width={140} height={140} src={divorcio} />
                <h2 className="fw-normal">{t("familia.texto5")}</h2>
                <NavLink to="/divorcioespana">
                <p><a className="btn  colordiv colortext" href="#">{t("textos.texto1")} »</a></p>
                </NavLink>
                <br />
                </div>{/* /.col-lg-4 */}
            </div>{/* /.row */}
          </div>{/* /.container */}
        </main>

        </div>

<br />
    <nav className="text-center blog-pagination" aria-label="Pagination">
        <a className="btn btn-outline-primary rounded-pill" href="#"><FontAwesomeIcon icon={faChevronUp} /></a>
      </nav><br />

      </DivContainer>
    </>
  );
}

const DivContainer = styled.div`
  padding: 0.5rem;
  .colordiv {
    background-color: #001952;
  }
  .colortext {
    color: white;
  }
`;

const DivBoton = styled.div`
  padding: 0.4rem;
  background: linear-gradient(to bottom right, #001952 60%, pink);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
