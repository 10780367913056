import React from "react";
import { NavLink } from "react-router-dom";
import "../assets/css/mycss.css";
import styled from "styled-components";

import laboral1_2400x1600 from "../assets/img/proyecto/derecho laboral/derecho laboral1.1.jpg";
import laboral2_2400x1600 from "../assets/img/proyecto/derecho laboral/laboral2.1.jpeg";
import laboral3_2400x1600 from "../assets/img/proyecto/derecho laboral/derecho laboral5_1.1.jpg";
import laboral4_2400x1600 from "../assets/img/proyecto/derecho laboral/derecho laboral6.1.jpeg";

import acoso from "../assets/img/proyecto/derecho laboral/acoso.png";
import contrato from "../assets/img/proyecto/derecho laboral/contratro.png";
import despido from "../assets/img/proyecto/derecho laboral/despido.png";
import incapacidad from "../assets/img/proyecto/derecho laboral/incapacidad.png";
import pensiones from "../assets/img/proyecto/derecho laboral/pensiones.png";

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight, faChevronUp } from "@fortawesome/free-solid-svg-icons";

export default function Laboral() {
  const [t, i18n] = useTranslation("global");
  return (
    <>
      <DivContainer>
        <div className="container">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/">{t("navbar.page1")}</NavLink>
                    </li>
                    <li className="breadcrumb-item active">
                      {t("body.texto6")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>

          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators"></ol>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  className="d-block w-100"
                  src={laboral1_2400x1600}
                  alt="First slide"
                />
                <div className="carousel-caption d-none d-md-block"></div>
              </div>
              <div className="carousel-item">
                <img
                  className="d-block w-100"
                  src={laboral2_2400x1600}
                  alt="Third slide"
                />
                <div className="carousel-caption d-none d-md-block"></div>
              </div>
              <div className="carousel-item">
                <img
                  className="d-block w-100"
                  src={laboral3_2400x1600}
                  alt="Third slide"
                />
              </div>
              <div className="carousel-item">
                <img
                  className="d-block w-100"
                  src={laboral4_2400x1600}
                  alt="Third slide"
                />
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="sr-only">Next</span>
            </a>
          </div>

          <br /><br /><br /><br />
          <main>
          {/* Wrap the rest of the page in another container to center all the content. */}
          <div className="container marketing">
            {/* Three columns of text below the carousel */}
            <div className="row">
              <div className="col-lg-3 text-center">
              <img className="bd-placeholder-img rounded-circle" width={140} height={140} src={acoso} />
              <h2 className="fw-normal">{t("laboral.texto1")}</h2>
                <NavLink to="/acosolaboral">
                <p><a className="btn colordiv colortext" href="#">{t("textos.texto1")} »</a></p>
                    </NavLink>
                    <br />
                    </div>{/* /.col-lg-4 */}
              <div className="col-lg-3 text-center">
                <img className="bd-placeholder-img rounded-circle" width={140} height={140} src={contrato} />
                <h2 className="fw-normal">{t("laboral.texto3")}</h2>
                <NavLink to="/contratolaboral">
                <p><a className="btn  colordiv colortext">{t("textos.texto1")} »</a></p>
                </NavLink>
                <br />
                </div>{/* /.col-lg-4 */}
              <div className="col-lg-3 text-center">
                <img className="bd-placeholder-img rounded-circle" width={140} height={140} src={despido} />
                <h2 className="fw-normal">{t("laboral.texto4")}</h2>
                <NavLink to="/despidoreduccion">
                <p><a className="btn  colordiv colortext" href="#">{t("textos.texto1")} »</a></p>
                </NavLink>
                <br />
                </div>{/* /.col-lg-4 */}
              <div className="col-lg-3 text-center">
                <img className="bd-placeholder-img rounded-circle" width={140} height={140} src={incapacidad} />
                <h2 className="fw-normal">{t("laboral.texto5")}</h2>
                <NavLink to="/incapacidadlaboral">
                <p><a className="btn  colordiv colortext" href="#">{t("textos.texto1")} »</a></p>
                </NavLink>
                <br />
                </div>{/* /.col-lg-4 */}
              <div className="col-lg-3 text-center">
                <img className="bd-placeholder-img rounded-circle" width={140} height={140} src={pensiones} />
                <h2 className="fw-normal">{t("laboral.texto6")}</h2>
                <NavLink to="/pensiones">
                <p><a className="btn  colordiv colortext" href="#">{t("textos.texto1")} »</a></p>
                </NavLink>
                <br />
                </div>{/* /.col-lg-4 */}

            </div>{/* /.row */}
          </div>{/* /.container */}
        </main>


        </div>


<br />
    <nav className="text-center blog-pagination" aria-label="Pagination">
        <a className="btn btn-outline-primary rounded-pill" href="#"><FontAwesomeIcon icon={faChevronUp} /></a>
      </nav><br />

      </DivContainer>
    </>
  );
}

const DivContainer = styled.div`
  padding: 0.5rem;
  .colordiv {
    background-color: #001952;
  }
  .colortext {
    color: white;
  }
`;
